import React from "react"
// import { graphql } from "gatsby"
import getValue from "get-value"
import PostHero from "@src/components/post-hero"
import SEO from "@components/seo"
import Section from "@components/section"
import AuthorBlock from "@components/blog/author"
import ContentBuilder from "@components/content-builder"
import RecommendedBlogs from "@src/components/recommended-blogs"
import InstagramFeed from "@components/instagram-feed"
import { placeholderImageId } from "@src/utils/image"

const BlogView = ({ pageContext }) => {
  const entry = getValue(pageContext, "entry")
  const itemCategories = getValue(entry, "categories")
  const author = getValue(entry, "author")
  const mainImageData = getValue(entry, "mainImage") || placeholderImageId
  // const relatedBlogs = getValue(data, "relatedBlogs");
  const relatedBlogs = pageContext?.relatedBlogs;
  
  const {
    title,
    date,
    contentBuilder: contentBuilderBlocks
  } = entry

  return (
    <>
      <SEO {...{ document: entry }} />
      <Section settings={{ isContainer: false, classname: '!mb-10' }}>
        <PostHero categories={itemCategories} bgImage={mainImageData} title={title} dateCreated={date} postType='blog'/>
      </Section>
      <ContentBuilder blocks={contentBuilderBlocks} className={'max-w-[1020px] mx-auto !my-10'}/>
      {author && <AuthorBlock author={author} />}
      <RecommendedBlogs blogs={relatedBlogs} />
      <Section settings={{ classname: 'overflow-hidden' }}>
        <InstagramFeed />
      </Section>
    </>
  )
}

export default BlogView
